// Generated by Framer (716dd6f)

import { addFonts, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["p1qM1junF"];

const variantClassNames = {p1qM1junF: "framer-v-18kb6ir"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "p1qM1junF", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "p1qM1junF", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-VTgjk", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 1120, intrinsicWidth: 912, pixelHeight: 1120, pixelWidth: 912, src: new URL("assets/6uWVUGpHmZObcR5fJMKbEXZGsE.png", import.meta.url).href, srcSet: `${new URL("assets/512/6uWVUGpHmZObcR5fJMKbEXZGsE.png", import.meta.url).href} 416w, ${new URL("assets/1024/6uWVUGpHmZObcR5fJMKbEXZGsE.png", import.meta.url).href} 833w, ${new URL("assets/6uWVUGpHmZObcR5fJMKbEXZGsE.png", import.meta.url).href} 912w`}} className={cx("framer-18kb6ir", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"p1qM1junF"} ref={ref} style={{...style}} transition={transition}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-VTgjk [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-VTgjk * { box-sizing: border-box; }", ".framer-VTgjk .framer-hcrzrh { display: block; }", ".framer-VTgjk .framer-18kb6ir { height: 560px; overflow: visible; position: relative; width: 456px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 560
 * @framerIntrinsicWidth 456
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FrameriMvfuJIp6: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FrameriMvfuJIp6;

FrameriMvfuJIp6.displayName = "Uncover insights";

FrameriMvfuJIp6.defaultProps = {height: 560, width: 456};

addFonts(FrameriMvfuJIp6, [])